var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('tit-wrap-subtitle', {
    staticClass: "mb-0 pb-12px pb-md-20px",
    attrs: {
      "titSize": "xs"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v("제휴문의")];
      },
      proxy: true
    }])
  }), _c('form-input', {
    attrs: {
      "code": "inquiry-partner",
      "skin": "inquiry-partner"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }