<template>
    <client-page>
        <tit-wrap-subtitle titSize="xs" class="mb-0 pb-12px pb-md-20px">
            <template #tit>제휴문의</template>
        </tit-wrap-subtitle>

        <form-input code="inquiry-partner" skin="inquiry-partner"></form-input>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import FormInput from "@/components/client/form/form-input.vue";
import TitWrapSubtitle from "@/components/client/dumb/tit-wrap-subtitle.vue";

export default {
    components: {
        ClientPage,
        FormInput,
        TitWrapSubtitle,
    },
};
</script>
